.navbar-main {
    background-color: white;
    height: auto;
    position: sticky !important;
    top: 0;
    left: 0;
    z-index: 100;
}

.menus-main {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    flex-wrap: wrap;
    color: #2f3349;
    font-weight: 600;
    font-size: 18px;

    .nav-link {
        color: #2f3349;
        padding: 10px 10px;
        margin: 0px 10px;
        height: 40px;
        position: relative;
    }

    .nav-link-2 {
        color: #2f3349;
        padding: 10px 10px;
        margin: 0px 10px;
        height: 40px;
        position: relative;

        span {
            position: absolute;
            top: 6px;
            left: 80px;
            width: 15px;
            height: 15px;

            .icon {
                width: 15px;
                height: 15px;
            }
        }
    }

    .menus-active {
        color: #8d3d04 !important;
        border-bottom: 3px solid #8d3d04;
        padding: 10px 10px;
        margin: 0px 10px;
        position: relative;

        span {
            position: absolute;
            top: 6px;
            left: 80px;
            width: 15px;
            height: 15px;

            .icon {
                width: 15px;
                height: 15px;
            }
        }
    }

    .nav-link:hover {
        border-bottom: 3px solid #8d3d04;
        padding: 10px 10px;
        margin: 0px 10px;
    }

    .dropdown-container {
        position: relative;
        display: inline-block;

        .sub-button {
            height: 50px;
            background: none;
            border: none;
            color: #2F3349;
            font-size: 18px;
            font-weight: 500;
            margin: 0px;
            padding: 10px 20px !important;
        }
    }

    .dropdown-menu {
        padding: 0px;
        position: absolute;
        top: 100%;
        left: -10px;
        background: white;
        border: 1px solid #ddd;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        min-width: 150px;
        z-index: 1000;
        display: none; // Initially hidden
    }

    .dropdown-container:hover .dropdown-menu {
        display: block; // Show on hover
    }

    .dropdown-menu .nav-link {
        display: block;
        padding: 8px 12px;
        color: #333;
        text-decoration: none;
        white-space: nowrap;
    }

    .dropdown-menu .sub-button:hover {
        background-color: lightblue;
        margin: 0px;
        border-bottom: none;
        padding: 10px 20px !important;
        border-radius: 5px;
    }
}

@media screen and (max-width: 991px) {
    .menus-main {
        background-color: white;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        color: #2f3349;
        font-weight: 600;
        font-size: 18px;

        .nav-link {
            padding: 10px 10px;
            margin: 0px 10px;
            height: 40px;
        }

        .menus-active {
            color: #8d3d04;
            border-bottom: 3px solid #8d3d04;
            padding: 10px 10px;
            margin: 0px 10px;
        }

        .nav-link:hover {
            border-bottom: 3px solid #8d3d04;
            padding: 10px 10px;
            margin: 0px 10px;
        }
    }
}