.about-component {
    .image-main {
        width: 100%;
        height: 300px;
        position: relative;

        .about-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 345px;
        /* You may adjust this width for different screen sizes if necessary */
        color: white;
        font-size: 40px;
        /* Adjust font size for responsiveness if needed */
        font-weight: bold;
        z-index: 2;
        text-align: center;
        /* Center text inside the element */
    }

    .overlay {
        height: 300px;
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.6);
        opacity: 0.6;
        z-index: 1;
    }

    .body-main {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;

        .content-one,
        .content-two,
        .content-three {
            width: 60%;          
            font-size: 20px;
            padding: 20px;
            margin: 0px;
        }
    }
}

@media screen and (min-width: 344px) and (max-width: 991px) {
    .about-component {
        .body-main {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 20px;

            .content-one,
            .content-two,
            .content-three {
                width: 90%;
                font-size: 20px;
                padding: 20px;
                margin: 0px;
            }
        }
    }
}