.footer-component {
    color: white;
    width: 100%;

    p {
        margin: 0px;
    }

    .row-one {
        background-color: #2f3349;
        width: 100%;
        padding: 20px 0px;
        margin: 0px;
    }

    .row-two {
        width: 100%;
        text-align: center;
        padding: 10px 0px;
        background-color: #202233;
        margin: 0px;
    }

    .col-1,
    .col-2,
    .col-3 {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px 0px;
    }

    .first-col {
        text-align: left;
    }

    .footer-logo {
        margin-bottom: 10px;
    }

    .heading {
        font-weight: bold;
        font-size: 22px;
        margin-bottom: 20px;
    }

    .link {
        margin-bottom: 10px;
        cursor: pointer;
    }

    .link:hover {
        color: blue;
    }

    .therd-col {
        .head {
            font-weight: bold;
            font-size: 22px;
            margin-bottom: 20px;
        }

        .gmail-main {
            margin-bottom: 10px;
        }
    }

    .copy-main {
        span {
            font-weight: bold;
            cursor: pointer;
        }

        span:hover {
            color: blue;
        }
    }

    .address-link,
    .email-link {
        text-decoration: none;
        /* Remove underline */
        color: inherit;
        /* Inherit the text color */

        &:hover {
            color: blue;
            /* Change color on hover if needed */
        }
    }
}

@media screen and (min-width: 375px) and (max-width: 575px) {
    .footer-component {
        .therd-col {
            margin-left: 32px !important;
        }
    }
}