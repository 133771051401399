.industries-component {
    .image-main {
        width: 100%;
        height: 300px;
        position: relative;

        .about-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .content {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 70%;
            /* You may adjust this width for different screen sizes if necessary */
            color: white;
            font-size: 40px;
            /* Adjust font size for responsiveness if needed */
            font-weight: bold;
            z-index: 2;
            text-align: center;
            /* Center text inside the element */
        }

        .overlay {
            height: 300px;
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(0, 0, 0, 0.6);
            opacity: 0.6;
            z-index: 1;
        }
    }

    .container {
        margin: 10px auto;

        .row {
            .col {
                margin: 30px 0px;
            }
        }

        .card {
            height: 300px;
            position: relative;
            overflow: hidden;
            text-align: center;
            border: none;
            transition: transform 0.3s ease-in-out;
            /* Smooth transition */
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            /* Subtle shadow */
        }

        .card:hover {
            transform: translateY(-0px) scale(1.02);
            /* Move the card slightly up and scale */
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
            /* Enhance shadow on hover */
        }

        .card img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
            /* Ensures the image covers the entire area */
        }

        .card-body {
            padding: 0;
        }

        .blur-area {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 10px 0;
            /* Adjust padding as needed */
            background: rgba(0, 0, 0, 0.6);
            /* Semi-transparent background */
            backdrop-filter: blur(8px);
            /* Apply blur effect */
            color: #fff;
            /* Text color */
            text-align: center;
            /* Center the text */
        }

        .blur-area p {
            margin: 0;
            font-size: 1.2rem;
            /* Adjust text size as needed */
            font-weight: bold;
        }
    }
}


@media screen and (min-width: 992px) and (max-width: 1400px) {
    .industries-component {
        .container {
            max-width: 100%;
        }
    }
}

@media screen and (min-width: 992px) and (max-width: 1140px) {
    .industries-component {
        .content {
            font-size: 35px !important;
        }
    }
}

@media screen and (min-width: 375px) and (max-width: 991px) {
    .industries-component {
        .content {
            font-size: 25px !important;
        }
    }
}