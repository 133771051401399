.team-componenet {
    .image-main {
        width: 100%;
        height: 300px;
        position: relative;

        .about-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .content {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 335px;
            /* You may adjust this width for different screen sizes if necessary */
            color: white;
            font-size: 40px;
            /* Adjust font size for responsiveness if needed */
            font-weight: bold;
            z-index: 2;
            text-align: center;
            /* Center text inside the element */
        }

        .overlay {
            height: 300px;
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(0, 0, 0, 0.6);
            opacity: 0.6;
            z-index: 1;
        }
    }


    .container {
        margin: 20px 0px;
        max-width: 100%;
        padding: 0px;

        .row {
            display: flex;
            justify-content: center;
            margin: 20px auto;
            padding: 20px 0px;
        }

        .img-main {
            height: 550px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .image-team {
            border-radius: 20px;
            max-width: 350px;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        .content-main {
            .name {
                font-weight: bold;
                font-size: 30px;
                color: #314c65;
            }

            .position {
                font-size: 20px;
                color: #38afff;
            }

            .content {
                font-size: 16px;
                color: #2f3349;
            }
        }
    }
}