.product-component {
    .image-main {
        width: 100%;
        height: 100vh;
        position: relative;

        .about-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .content {
        position: absolute;
        top: 50%;
        right: 0%;
        width: 56%;
        /* You may adjust this width for different screen sizes if necessary */
        color: white;
        font-size: 40px;
        /* Adjust font size for responsiveness if needed */
        font-weight: bold;
        z-index: 2;
    }

    .overlay {
        height: 100vh;
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.6);
        opacity: 0.6;
        z-index: 1;
    }

    .product-content {
        max-width: 100% !important;
        margin: 0px;
        padding: 0px;

        .row {
            width: 100%;
            margin: 0px;
        }

        .col {
            padding: 20px 200px;

            p {
                color: #2f3349;
                font-size: 1.25rem !important;
                margin: 0px;

                .link {
                    color: rgb(12, 190, 250);

                    &:hover {
                        color: rgb(0, 130, 174);
                        cursor: pointer;
                        text-decoration: underline;
                    }
                }
            }
        }

        .col-image {
            display: flex;
            justify-content: center;

            .image-first {
                width: 75%;
            }
        }

        .col-3 {
            display: flex;
            justify-content: center;
            width: 100%;
            padding-top: 0px;

            ol {
                width: 90%;
            }

            li {
                color: #2f3349;
                font-size: 1.25rem !important;
                margin: 0px;
            }
        }
    }
}

@media screen and (min-width: 344px) and (max-width: 1200px) {
    .product-component {
        .col {
            padding: 20px 20px !important;
        }

        .col-image {
            .image-first {
                width: 100% !important;
            }
        }

        .col-3 {
            padding-top: 0px !important;

            ol {
                width: 90% !important;
            }
        }
    }
}

@media screen and (min-width: 1000px) and (max-width: 1400px) {
    .product-component {
        .content {           
            font-size: 30px !important;
        }
    }
}

@media screen and (min-width: 344px) and (max-width: 768px) {
    .product-component {
        .content {
            position: absolute;
            top: 50%;
            right: 0%;
            width: 90% !important;
            color: white;
            font-size: 40px;
            font-weight: bold;
            z-index: 2;
        }
    }
}