.banner-container {
    position: relative;
    height: calc(100vh - 100px);
    overflow: hidden;

    .banner-text {
        position: absolute;
        top: 20%;
        left: 12%;
        right: 15%;
        z-index: 2;
        color: #fff;
        text-align: left;

        h3 {
            font-size: 3rem;
            font-weight: bold;
            line-height: 60px;
            margin: 0;
        }
    }

    .carousel-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .carousel-item img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.drk:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
    opacity: 0.6;
    z-index: 1;
}

@media screen and (max-width: 576px) {
    .banner-text  {
        position: absolute;
        right: 10% !important;
        top: 5rem;
        bottom: 4rem !important;
        left: 12% !important;
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
        color: #fff;
        text-align: left !important;
    }
}

@media screen and (min-width: 340px) and (max-width: 1000px) {
    .banner-text {
        h3 {
            font-size: 30px !important;
            font-weight: bold;
            line-height: 35px !important;
        }
    }
}

@media screen and (min-width: 1000px) and (max-width: 1400px) {
    .banner-text {
        position: absolute;
        right: 15% !important;
        top: 20%;
        bottom: 4rem !important;
        left: 12% !important;
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
        color: #fff;
        text-align: left !important;

        h3 {
            font-size: 30px !important;
            font-weight: bold;
            line-height: 35px !important;
        }
    }
}
