.home-about-staffing {
    max-width: 100% !important;
    width: 100%;
    height: 600px;
    background-color: #e6ebe4;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px !important;

    .col {
        display: flex;
        align-items: center;

        .left-side-image {
            width: 100%;
            height: 350px;
            padding: 0px 30px;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            img {
                width: 65%;
                height: 100%;
                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.16);
                object-fit: cover;
            }
        }
    }

    .col {

        .right-side-image {
            width: 100%;
            padding: 0px 30px;

            .content-main {
                width: 70%;
                display: flex;
                flex-direction: column;
            }

            .heading {
                color: #2f3349;
                font-size: 2.5rem;
                font-weight: bold;
                line-height: 50px;
            }

            .content {
                color: #2f3349;
                font-size: 1.25rem;

                .link {
                    color: rgb(12, 190, 250);

                    &:hover {
                        color: rgb(0, 130, 174);
                        cursor: pointer;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 992px) and (max-width: 1550px) {
    .home-about-staffing {

        .left-side-image {
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 100% !important;
            }
        }

        .content-main {
            width: 100% !important;

            .heading {
                font-size: 30px !important;
                line-height: 40px !important;
            }

            .content {
                font-size: 18px !important;
            }
        }
    }
}

@media screen and (min-width: 340px) and (max-width: 991px) {
    .home-about-staffing {
        height: fit-content;

        .left-side-image {
            display: flex;
            justify-content: center !important;
            align-items: center;
            padding: 20px 0px !important;

            img {
                width: 100% !important;
            }
        }

        .right-side-image {
            width: 100%;
            padding: 0px 30px;
            display: flex !important;
            justify-content: center;
            align-items: center !important;
            padding: 20px 0px !important;

            .content-main {
                width: 100% !important;
            }
        }
    }
}