.about-card-component {
    margin: 30px 0px;

    .first-row {
        margin: 20px 0px;
    }

    .col {
        padding: 10px 10px;
    }

    .first-card {
        background-color: #FEF3DF;
        border: none;
        border-radius: 0px;
        min-height: 530px;
        height: 600px;

        .first-image {
            width: 100%;
            height: 250px;
            margin-bottom: 20px;
            object-fit: cover;
        }

        .card-body {
            display: flex;
            flex-direction: column;
            align-items: center;

            .sub-heading {
                color: #2f3349;
                font-size: 18px;
                font-weight: bold;
                line-height: 26px;
            }

            .heading {
                color: #2f3349;
                font-size: 22px;
                font-weight: bold;
                line-height: 26px;
            }

            .body {
                font-size: 20px;
                line-height: 30px;
                padding: 0px 20px;
            }
        }
    }

    .second-card {
        border: none;
        border-radius: 0px;
        min-height: 300px;

        .card-body {
            .center-content {
                display: flex;
                justify-content: center;
            }

            .image-main {
                width: 50%;
                height: 500px;

                .values-image {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .content-main {
                width: 50%;
            }

            .wrap-content {
                margin: 0px 20px;
            }

            .sub-heading {
                color: #2f3349;
                font-size: 18px;
                font-weight: bold;
                line-height: 26px;
                margin-bottom: 10px;
                padding: 0px 20px;
            }

            .heading {
                color: #2f3349;
                font-size: 22px;
                font-weight: bold;
                line-height: 26px;
                text-align: center;
            }

            .body {
                font-size: 20px;
                line-height: 30px;
                padding: 0px 20px;
            }
        }
    }
}

@media screen and (min-width: 344px) and (max-width: 767px) {
    .about-card-component {
        .second-card {
            .card-body {
                .image-main {
                    width: 100%;
                    height: 250px !important;

                    .values-image {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }

        .first-card {
            height: 665px !important;
        }
    }
}

@media screen and (min-width: 344px) and (max-width: 992px) {
    .about-card-component {
        .first-image {
            object-fit: fill !important;
        }
    }
}

@media screen and (min-width: 344px) and (max-width: 1199px) {
    .about-card-component {
        .second-card {
            .card-body {
                display: flex;
                flex-direction: column;

                .image-main {
                    width: 100%;
                    height: 400px;

                    .values-image {
                        width: 100%;
                        height: 100%;
                    }
                }

                .content-main {
                    width: 100%;
                    margin-top: 10px;
                }
            }
        }
    }
}