.product-one-component {
    width: 100%;
    background: rgb(3, 31, 70);
    background: linear-gradient(180deg, rgba(3, 31, 70, 1) 0%, rgba(77, 116, 171, 1) 100%);

    .image-main {
        width: 100%;
        height: 100%;
        position: relative;

        .about-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .container {
        padding: 20px 120px;
    }

    .container-two {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        color: white;

        .heading-one-content {
            font-size: 30px;
            font-weight: bold;
        }

        .heading-two-content {
            margin: 20px 15px;
            font-size: 20px;
            font-weight: 400;
        }

        .heading-three {
            text-align: center;
            font-size: 30px;
            font-weight: bold;
            margin-bottom: 0px;
        }
    }

    .cards-main {

        .card,
        .card-two {
            margin: 20px 15px;

            .card-body {
                height: 300px;
                border-radius: 10px;
                margin: 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                text-align: center;
                padding: 0px;

                .content-main {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 0px 40px;
                }

                .input-main {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-end;
                    padding: 0px 40px;

                    .message {
                        text-align: left;
                        width: 350px;
                        min-height: 100px;
                        height: auto;
                        border: 1px solid #29244d;
                        border-radius: 20px 20px 0px 20px;
                        font-size: 20px;
                        padding: 10px 10px;
                        background-color: #f5f5f7;
                        margin-top: 10px;
                    }

                    .input-container {
                        position: relative;
                        display: flex;
                        align-items: center;
                        width: 100%;

                        .input {
                            text-align: left;
                            width: 835px;
                            padding: 10px 0px;
                            padding-left: 50px; // Adjust space for icon
                            padding-right: 10px;
                            min-height: 50px;
                            height: auto;
                            border: none;
                            border-radius: 5px;
                            background-color: #29244d;
                            color: #fff;
                            font-size: 20px;
                            margin-bottom: 0px;
                        }

                        .icon {
                            position: absolute;
                            top: 10px;
                            left: 10px;
                            font-size: 30px;
                            fill: #fff !important;
                            margin: 0px 10px 0px 0px;
                        }
                    }
                }

                .icon-main {
                    width: 100px;
                    height: 100px;
                    margin-bottom: 5px;

                    .real-icon {
                        width: 100%;
                        height: 100%;
                    }
                }

                .heading {
                    font-size: 22px;
                    font-weight: 400;
                }

                .content {
                    font-size: 18px;
                    margin-bottom: 0px;
                }
            }
        }
    }

    @media screen and (max-width: 767px) {
        .card-body {
            height: 230px !important;
        }
    }

    @media screen and (min-width: 1200px) and (max-width: 1399px) {
        .card-body {
            height: 320px !important;
        }
    }

    @media screen and (min-width: 992px) and (max-width: 1199px) {
        .card-body {
            height: 400px !important;
        }

        .card-two {
            height: 220px !important;

            .content-main {
                padding: 0px 10px !important;
                width: 50%;
            }

            .input-main {
                padding: 0px 10px !important;
                width: 50%;
            }
        }
    }

    @media screen and (min-width: 768px) and (max-width: 992px) {
        .card-body {
            height: 520px !important;
        }

        .card-two {
            height: 280px !important;

            .input {
                height: 80px !important;
            }

            .content-main {
                padding: 0px 10px !important;
                width: 50%;
            }

            .input-main {
                padding: 0px 10px !important;
                width: 50%;

                .message {
                    width: 80% !important;
                    min-height: 100px !important;
                }

                .input {
                    width: 100% !important;
                    height: auto !important;
                }
            }
        }
    }

    @media screen and (min-width: 465px) and (max-width: 767px) {
        .card-body {
            height: auto !important;
        }

        .card-two {
            height: auto !important;

            .content-main {
                padding: 0px 10px !important;
            }

            .input-main {
                padding: 0px 10px !important;

                .message {
                    width: 100% !important;
                    min-height: 100px !important;
                }

                .input {
                    width: 100% !important;
                    height: auto !important;
                }
            }
        }
    }

    @media screen and (max-width: 464px) {
        .card-body {
            height: auto !important;
        }

        .card-two {
            height: auto !important;

            .content-main {
                padding: 0px 10px !important;
            }

            .input-main {
                padding: 0px 10px !important;
            }

            .message {
                width: 100% !important;
                min-height: 100px !important;
            }

            .input {
                width: 100% !important;
                height: auto !important;
            }
        }
    }
}