.consulting-component {
    .image-main {
        width: 100%;
        height: 100vh;
        position: relative;

        .about-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .content {
        position: absolute;
        top: 10%;
        right: 2%;
        width: 32%;
        color: white;
        font-size: 36px;
        z-index: 2;
    }

    .overlay {
        height: 100vh;
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.6);
        opacity: 0.6;
        z-index: 1;
    }

    .consulting-content {
        max-width: 100%;

        .row {
            padding: 20px 250px;
        }

        .col {
            padding: 20px 0px;

            p {
                color: #2f3349;
                font-size: 1.25rem !important;
                margin: 0px;
            }
        }

        li {
            color: #2f3349;
            font-size: 1.25rem !important;
            margin: 0px;
        }
    }
}

@media screen and (min-width: 344px) and (max-width: 1200px) {
    .consulting-component {
        .row {
            padding: 20px 20px !important;
        }
    }
}

@media screen and (min-width: 344px) and (max-width: 575px) {
    .consulting-component {
        .content {
            position: absolute;
            top: 20%;
            right: 0%;
            width: 90% !important;
            color: white;
            font-size: 28px;
            z-index: 2;
        }
    }
}

@media screen and (min-width: 992px) and (max-width: 1300px) {
    .consulting-component {
        .content {
            position: absolute;
            top: 20% !important;
            right: 0%;
            width: 40% !important;
            color: white;
            font-size: 36px;
            z-index: 2;
        }
    }
}

@media screen and (min-width: 576px) and (max-width: 991px) {
    .consulting-component {
        .content {
            position: absolute;
            top: 15% !important;
            right: 0%;
            width: 60% !important;
            color: white;
            font-size: 36px;
            z-index: 2;
        }
    }
}